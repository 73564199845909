import richTextEditorUi from "./rich-text-editor.ui";

var elmStates = {};
type ELM_TYPES = 'text' | 'number' | 'date' | 'email' | 'time' | 'textarea' | 'function' | 'boolean' | 'file' | 'richtext' | 'password'
type UIElm = { name, title, type: ELM_TYPES, params }
export default class Form {
    deleteFn: () => any;
    cancelFn: () => any;
    setCancel(cancelFn: () => any) {
        this.cancelFn = cancelFn
        return this;
    }
    setDelete(deleteFn: () => void) {
        this.deleteFn = deleteFn
        return this;
    }
    reset(newvals?) {
        this.values = {}
        this.submitting = false;
        this.cancelFn = this.deleteFn = null;
        this.error = null;
        if (newvals) { this.values = { ...newvals } }
        return this;
    }
    private elements: UIElm[] = []
    private error: any
    private submitting: boolean
    constructor(private title?, private submitLabel?) {

    }

    setLabel(type: 'submit', lbl) {
        this.submitLabel = lbl
        return this;
    }

    add(name, title, type: ELM_TYPES, params: { newOnly?, yesLabel?, noLabel?, required?, disabled?, options?: any[], renderFn?: (form: Form) => any } = {}) {
        this.elements.push({ name, title, type, params })
        return this;
    }
    private submitFn: (values, callback: (errors) => void, successAndEnable:()=>void) => void

    setSubmit(fn: (values, failed: (errors) => void, successAndEnable?: () => void) => void) {
        this.submitFn = fn
        return this;
    }
    values: any = {}
    paint() {
        return [
            this.elements.map(elm => {
                if (elm.params.newOnly && this.values.id) { return }
                let disabed = typeof elm.params?.disabled == 'function' ? elm.params?.disabled(this.values): elm.params?.disabled
                switch (elm.type) {
                    case 'boolean':
                        return m('[style=margin-top:15px]', [
                            m('', elm.title),
                            m('.btn-group', [
                                m('button.btn.btn-sm.btn-outline-primary', {disabed, className: +this.values[elm.name] === 0 ? 'active' : '', onclick: () => this.values[elm.name] = 0 }, elm.params.noLabel || 'Off'),
                                m('button.btn.btn-sm.btn-outline-primary', {disabed, className: +this.values[elm.name] === 1 ? 'active' : '', onclick: () => this.values[elm.name] = 1 }, elm.params.yesLabel || 'On'),
                            ])
                        ])
                    case 'richtext':
                        return m('[style=margin-top:15px]', [
                            m('.navbar', elm.title, m('.btn-group.m-2', [
                                m('button.btn.btn-outline-secondary.btn-sm', { onclick: () => elmStates[elm.name] = 'rich', className: (elmStates[elm.name] || 'rich') == 'rich' ? 'active' : '' }, 'Rich Editor'),
                                m('button.btn.btn-outline-secondary.btn-sm', { onclick: () => elmStates[elm.name] = 'ceditable', className: elmStates[elm.name] == 'ceditable' ? 'active' : '' }, 'Content Editable'),
                                m('button.btn.btn-outline-secondary.btn-sm', { onclick: () => elmStates[elm.name] = 'html', className: elmStates[elm.name] == 'html' ? 'active' : '' }, 'HTML'),
                            ])),
                            (elmStates[elm.name] || 'rich') == 'rich' && m(richTextEditorUi, { set: val => this.values[elm.name] = val, val: this.values[elm.name] }),
                            (elmStates[elm.name]) == 'ceditable' && m('[contenteditable=true][style=padding:15px;border:2px solid #EEE]', { onblur: e => this.values[elm.name] = e.target.innerHTML, innerHTML: this.values[elm.name] }),
                            (elmStates[elm.name]) == 'html' && m('textarea[style=width:100%]', { oninput: e => this.values[elm.name] = e.target.value, value: this.values[elm.name] }),

                        ])
                    case 'text':
                    case 'password':
                    case 'number':
                    case 'date':
                    case 'email':
                    case 'time':
                        if (elm.params.options) {
                            return m('[style=margin-top:15px]', [
                                m('', elm.title),
                                m('.btn-group', elm.params.options.map(o => m('button.btn-sm.btn.btn-outline-primary', {disabed, className: this.values[elm.name] == o.value ? 'active' : '', onclick: () => this.values[elm.name] = o.value || o || o.value }, o.label || o)))
                            ])
                        }
                        return m('[style=margin-top:15px]', [
                            m('', elm.title),
                            m(`input.form-control.form-control-sm[type=${elm.type}]`, {disabed, oninput: e => this.values[elm.name] = e.target.value, value: this.values[elm.name] })
                        ])
                    case 'file':
                        return m('[style=margin-top:15px]', [
                            m('', elm.title),
                            m(`input[type=file].form-control-file`, {disabed, oninput: e => this.values[elm.name] = e.target.files[0] }),
                            typeof this.values[elm.name] == 'string'
                            && m('img[style=width:20px]', { src: this.values[elm.name] })
                        ])
                    case 'textarea':
                        return m('[style=margin-top:15px]', [
                            m('', elm.title),
                            m(`textarea.form-control.form-control-sm`, { oninput: e => this.values[elm.name] = e.target.value, value: this.values[elm.name] })
                        ])
                    case 'function':
                        return elm.params.renderFn(this)

                }
            }),
            this.errorBox(),
            this.controls()
        ]
    }
    private controls() {
        return m('.mt-3', [
            this.cancelFn && m('button.btn.btn-secondary', { onclick: () => this.cancelFn() }, 'Cancel'),
            m('button.btn.btn-primary.ml-1', {
                disabled: !!this.submitting,
                onclick: () => {
                    if (this.submitting) { return }
                    this.submitting = true
                    this.submitFn(this.values, (err) => {
                        if (err) {
                            this.error = err
                            this.submitting = false
                        }
                    }, () => {
                        this.submitting = false
                    })
                }
            }, this.submitLabel || 'Submit'),
            this.deleteFn && m('button.btn.btn-outline-danger.ml-1', { onclick: () => this.deleteFn() }, 'Delete'),
        ])
    }
    private errorBox() {
        return this.error && m('[style=background:red;color:white;padding:15px;margin:15px;]', this.error)
    }
}