import Form from "../../admin/ui-components/form"
import globalVars from "../global-vars";
let siteTitle
export default {
    oninit: ({ state }) => {
        m.request('api/users/init-load')
            .then(v => {
                state.chalukas = v.chalukos;
                (v.title || {}).value && (globalVars.siteTitle = v.title.value);
                let ch
                if (state.chalukas.length == 1) {
                    ch = state.chalukas[0]

                } else if (m.route.param('c')) {
                    ch = state.chalukas?.find(c => c.id == m.route.param('c') || c.urlname == m.route.param('c'))
                    if(ch){
                        state.cid = ch.id
                    }
                }
                if (ch) {
                    state.popup = () => m('', m(Chaluka, {
                        chalukas: state.chalukas, key: ch.id, ch, done: () => {
                            state.chalukas.length > 1 && (state.popup = null)
                            if(m.route.param('c')){
                                m.route.set(`?c=`)
                            }
                        }
                    }))
                }
            })
    },
    onupdate: ({ state }) => {
        if (m.route.param('c') && m.route.param('c') != state.cid) {
            let ch = state.chalukas?.find(c => c.id == m.route.param('c') || c.urlname == m.route.param('c'))
            if (ch) {
                state.cid = ch.id
                state.popup = () => m('', m(Chaluka, {
                    chalukas: state.chalukas, key: ch.id, ch, done: () => {
                        state.chalukas.length > 1 && (state.popup = null)
                        if(m.route.param('c')){
                            m.route.set(`?c=`)
                        }
                    }
                }))
                m.redraw()
                }
                }else if(!m.route.param('c') && state.cid){
                    state.cid = null
                    state.popup = null
                    m.redraw()
        }

    },
    view: ({ state }) => {
        return m('[style=;background-size:cover;height:100vh;overflow:auto;background-color:#;background-position:center;]', [
            m('.mb-3.navbar[style=border-top:2px solid #EEE;border-bottom:2px solid #EEE;background:rgba(1, 1, 1, 0.18);]', [
                m('[style=display:flex;align-items:center]', [
                    m('img[src=dist/images/302-logo.png][style=width:50px;]'),
                    m('h3.ml-4', m.trust(globalVars.siteTitle))
                ])
            ]),
            state.popup
                ? state.popup()
                : [
                    m('.col-md-6.offset-md-3', (state
                        .chalukas || [])
                        .map(ch => m('.m-2.card', {
                            onclick: () => {
                                m.route.set(`?c=${ch.urlname || ch.id}`)
                                // state.popup = () => m('', m(Chaluka, { chalukas: state.chalukas, key: ch.id, ch, done: () => { state.chalukas.length > 1 && (state.popup = null) } }))
                            }
                        }, [
                            m('.card-header', m('.navbar', [
                                ch.title,
                                m('.progress[style=width:200px;position:relative].m-1', [
                                    m('[style=position:absolute;left:50%;transform:translateX(-50%)]', [
                                        `${(+(ch.allocated_total || 0) / (+(ch.allocated_total || 0) + +ch.nonallocated_total) * 100).toFixed(0)}%`,
                                        ` ----- ${+ch.nonallocated_total} Remaining`
                                    ]),
                                    m('.progress-bar', {
                                        style: `width:${(+(ch.allocated_total || 0) / (+(ch.allocated_total || 0) + +ch.nonallocated_total) * 100).toFixed(0)}%`
                                    }, [

                                    ])]
                                ),
                                m('button.btn.btn-outline-primary', {}, 'Open Chaluka')
                                // ch.description && m('.card-body', m.trust(ch.description)),
                            ]))
                        ])))]
        ])
    }
}

let Chaluka = {
    oninit: ({ state, attrs }) => {
        (state.reload = () => m.request(`api/users/chaluka-parts/${attrs.ch.id}`).then(parts => {
            state.parts = parts.map(p => {
                p.allocated = !!+p.allocated
                return p;
            })
            let lastbookname
            let lastbook
            let newparts = []
            parts.map(p => {
                if (lastbookname != p.book) {
                    lastbookname = p.book;
                    lastbook && newparts.push(lastbook);
                    lastbook = { name: p.book, parts: [] }
                }
                if (!+p.allocated) {
                    lastbook.available = true
                }
                lastbook.parts.push(p)
            })
            lastbook && newparts.push(lastbook);
            state.organizedParts = newparts;
            m.redraw()
        }))();
        state.allocated = {}
    },
    view: ({ state, attrs }) => {
        let { ch, done, chalukas } = attrs
        if (state.confirm) {
            return m('.col-md-6.offset-md-3', m('.card', [
                m('.card-body', [
                    m('h4', [
                        'Your entry was posted. Thank you for taking part in this initiative.',

                    ]),
                    m('button.btn.btn-outline-secondary', { onclick: () => { state.confirm = false; state.submit = false; state.allocated = {}; state.reload(); done() } }, 'Done')
                ])]))
        }
        if (state.submit) {
            return m('.col-md-6.offset-md-3', m('.card', [
                m('.card-body', [
                    m('.m-2', [
                        m('', 'Selected'),
                        Object.keys(state.allocated).map(pk => m('.card.badge.p-1.m-1', `${state.allocated[pk].book} ${state.allocated[pk].segment}`)),
                    ]),
                    state.form.paint()
                ])
            ]))
        }
        return m('.col-md-8.offset-md-2', !state.organizedParts ? 'Loading...' : m('.card', [
            m('[style=position:relative].card-header', [
                m('.card-title', m('.navbar', [
                    // ch.title,
                    '',
                    chalukas.length > 1 && m('button.btn.btn-outline-secondary.btn-sm[title=Close]', { onclick: () => done() }, 'Close')
                ])),
                m('', m.trust(ch.description)),
                m('.navbar', [
                    m('', [
                        'Click your pages and select "Continue" to register your entry',
                        m('br'),

                    ]),

                    m('', [
                        Object.keys(state.allocated).length + ' Selected',
                        m('button.btn.btn-success.m-2', {
                            disabled: Object.keys(state.allocated).length == 0,
                            onclick: () => {
                                state.submit = true
                                state.form = new Form()
                                    .add('first_name', 'First Name', 'text')
                                    .add('last_name', 'Last Name', 'text', { required: true })
                                    .add('duch_name', 'Full Hebrew Name with Mother', 'text')
                                    .add('email', 'Email', 'email')
                                    .add('city', 'City', 'text')
                                    .add('state', 'State', 'text')
                                    .add('country', 'Country', 'text')
                                    .setCancel(() => { state.submit = false })
                                    .setSubmit((vals, err) => {
                                        m.request({
                                            url: `api/users/chaluka-parts/${ch.id}/allocate`,
                                            method: 'post',
                                            body: {
                                                ...vals,
                                                ids: Object.keys(state.allocated),
                                            }
                                        }).then(res => {
                                            state.confirm = true
                                            state.submit = false;
                                            state.reload()
                                        })
                                    })
                            }
                        }, 'Continue'),
                    ])
                ])
            ]),

            m('[style=max-height:80vh;overflow-x:auto].card-body', [
                m('.btn-group', [
                    m('button.btn.btn-outline-secondary.btn-sm', {
                        onclick: () => state.hideAllocated = false,
                        className: state.hideAllocated ? '' : 'active'
                    }, ch.chaluka_type == 'Mishna' ? 'All Perakim' : 'All Dapim'),
                    m('button.btn.btn-outline-secondary.btn-sm', {
                        onclick: () => state.hideAllocated = true,
                        className: !state.hideAllocated ? '' : 'active'
                    }, ch.chaluka_type == 'Mishna' ? 'Available Perakim' : 'Available Dapim')
                ]),
                m(ch.chaluka_type == 'Mishna - Masechtas' ? '[style=gap: 16px; display: grid; grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));]' : 'table', (state.organizedParts || [])
                    .map(book => {
                        if (state.hideAllocated && !book.available) { return }
                        if (ch.chaluka_type == 'Mishna - Masechtas') {
                            return book.parts.map(part => state.hideAllocated && part.allocated ? null : m(`button.btn.m-2.btn-outline-primary${state.allocated[part.id] ? '.active' : ''}`, {
                                disabled: !!part.allocated,
                                onclick: () => {
                                    if (state.allocated[part.id]) {
                                        delete state.allocated[part.id]
                                    } else {
                                        state.allocated[part.id] = part
                                    }
                                    m.redraw()
                                }
                            }, [part.book,

                            part.person_description && m('', `${part.person_description}`)]))
                        }
                        return [
                            m('tr', [
                                m('td[colspan=2][style=direction:rtl;    text-align: right;font-size:1.3em;font-weight:bold;].pt-2', book.name),
                            ]),
                            m('tr', [
                                m('td.p-2', [
                                    m('[style=display:flex;flex-wrap:wrap;direction:rtl]', [
                                        book.parts.map(part => state.hideAllocated && part.allocated ? null : m(`button.btn.m-2.btn-outline-primary${state.allocated[part.id] ? '.active' : ''}`, {
                                            disabled: !!part.allocated,
                                            onclick: () => {
                                                if (state.allocated[part.id]) {
                                                    delete state.allocated[part.id]
                                                } else {
                                                    state.allocated[part.id] = part
                                                }
                                                m.redraw()
                                            }
                                        }, [part.segment,

                                        part.person_description && m('', `${part.person_description}`)]))
                                    ])
                                ]),


                                // m('td', [
                                //     m('label', [
                                //         m('input[type=checkbox]', {
                                //             onclick: () => {

                                //             },
                                //             checked: !!state.allocated[book.id]
                                //         }),
                                //         'Mark'
                                //     ])
                                // ]),

                            ])]
                    }))
            ])
        ]))
    }
}