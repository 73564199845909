
let ready, requested
export default {
    oninit: ({ state, attrs }) => {
        state._id = (Math.random() * 100000).toFixed() + ""
        state.initVal = attrs.val
        if (document.getElementById('quilleditor')) {

        } else {
            requested = true
            var js = document.createElement("script");
            js.type = "text/javascript";
            js.id = 'quilleditor'
            js.src = 'https://cdn.quilljs.com/1.3.6/quill.js';
            js.onload = () => { ready = true; m.redraw() }
            document.body.appendChild(js);

            state.loaded = 0
            let js2 = document.createElement("link");
            js2.rel = "stylesheet";
            js2.href = 'https://cdn.quilljs.com/1.3.6/quill.snow.css';
            document.body.appendChild(js2);
        }
    },
    view: ({ state, attrs }) => {
        if (!ready) { return 'Loading...' }
        let { val, set } = attrs
        return m('', [
            m(`#editor${state._id}`, {
                onclick: (e) => {
                    e.stopPropagation()
                },
                onmousedown: (e) => {
                    e.stopPropagation()
                },
                oncreate: v => {
                    Object.keys(Quill.imports).map(k => k.indexOf('/style/') > -1 && Quill.register(Quill.import(k), true));
                    // Quill.register(AlignClass,true);
                    let q = state.q = new Quill(`#editor${state._id}`, {
                        theme: 'snow',
                        modules: {
                            toolbar: toolbarOptions
                        },
                        handlers: {
                            image: () => alert('4')
                        },
                        imageHandler: (image, callback) => {
                            console.log('image', image)
                        }

                    });
                    q.on('editor-change', () => {
                        const tempCont = document.createElement('div');
                        const tempEditor = new Quill(tempCont);
                        tempEditor.setContents(q.getContents());
                        set('' + tempEditor.root.innerHTML, '' + tempEditor.root.innerText);
                    })
                    m.redraw();
                },
                innerHTML: state.initVal
            })
        ])
    }
}


var toolbarOptions = [
    ['link','image', 'bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean']                                         // remove formatting button
];